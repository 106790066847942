<template>
    <div>
        <Navbar 
            :page="`Diagnóstico ${(diagnostico && diagnostico.diag_base ? diagnostico.diag_base.tema : '') + ' - ' + (diagnostico && diagnostico.destinatarioAgente ? diagnostico.destinatarioAgente.nome : (diagnostico && diagnostico.destinatarioPessoa ? diagnostico.destinatarioPessoa.nome : ''))}`"
            :link="`/diagnosticos/visualizar/${$route.params.id}`" nameLink="Visualizar diagnósticos aplicado" 
        />
        <div v-if="diagnostico" class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="my-2">
                Status:
                    
                <select v-model="diagnostico.status" name="grauDerisco" id="grauDerisco" 
                    class="text-black py-2 w-44 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm">
                    <option value="Em andamento">Em andamento</option>
                    <option value="Visualizado">Visualizado</option>
                    <option value="Finalizado">Finalizado</option>
                </select>
            </div>

            <div class="bg-gray-200 py-2 px-4 rounded">
                <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-6">
                        <label class="text-left text-xs font-medium text-gray-500 tracking-wider">Questão</label>
                    </div>
                    <div class="col-span-4">
                        <label class="text-left text-xs font-medium text-gray-500 tracking-wider">Resposta</label>
                    </div>
                    <div class="col-span-2">
                        <label class="text-left text-xs font-medium text-gray-500 tracking-wider">Resposta</label>
                    </div>
                </div>
            </div>

            <div v-for="(item, idx) in perguntas" :key="item._id" class="border-b-2 border-gray-200 py-2 px-4 mb-2">
                <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-12 md:col-span-6">
                        <label class="text-left text-sm font-medium text-black"><b>{{ idx + 1 }} - </b>{{ item.pergunta }}</label>
                    </div>
                    <div class="col-span-12 md:col-span-4">
                        <div class="mt-2 w-full">
                            <label :for="`pergunta-${idx}-Sim`" class="inline-flex items-center">
                                <input type="radio" class="form-radio" :for="`pergunta-${idx}-Sim`" :name="`pergunta-${idx}`" v-model="item.resposta" value="Sim">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Sim</span>
                            </label>
                            <label :for="`pergunta-${idx}-Nao`" class="inline-flex items-center ml-1 md:ml-2">
                                <input type="radio" class="form-radio" :for="`pergunta-${idx}-Nao`" :name="`pergunta-${idx}`" v-model="item.resposta" value="Não">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Não</span>
                            </label>
                            <label :for="`pergunta-${idx}-Nao`" class="inline-flex items-center ml-1 md:ml-2">
                                <input type="radio" class="form-radio" :for="`pergunta-${idx}-parcialmente`" :name="`pergunta-${idx}`" v-model="item.resposta" value="Parcialmente">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Parcialmente</span>
                            </label>
                            <label :for="`pergunta-${idx}-Naoaplica`" class="inline-flex items-center ml-1 md:ml-2">
                                <input type="radio" class="form-radio" :for="`pergunta-${idx}-Naoaplica`" :name="`pergunta-${idx}`" v-model="item.resposta" value="Não se aplica">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Não se aplica</span>
                            </label>
                            <label :for="`pergunta-${idx}-Outro`" class="inline-flex items-center ml-1 md:ml-2">
                                <input :class="{'opacity-50': diagnostico.status === 'Finalizado'}" :disabled="diagnostico.status === 'Finalizado'" type="checkbox" class="form-radio" :for="`pergunta-${idx}-Outro`" :name="`pergunta-${idx}`" v-model="item.comobs" value="Outro">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Observações</span>
                            </label>
                            <textarea :class="{'opacity-50': diagnostico.status === 'Finalizado'}" :disabled="diagnostico.status === 'Finalizado'" rows="3" type="text" v-if="item.comobs" v-model="item.outroResposta" placeholder="Resposta" :name="`pergunta-${idx}-Outro-Resposta`" class="block mt-2 focus:ring-yellow-400 focus:border-yellow-400 w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-2">
                        <label class="text-left text-xs font-medium text-gray-500 tracking-wider">{{ item.resposta }}</label>
                        <button type="button" @click="addEvidencia(idx)" class="block mt-2 justify-center py-1 px-2 border border-transparent shadow-sm text-xs_3 font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                            Adicionar evidência
                        </button>
                    </div>
                    <div class="col-span-12 md:col-span-12">
                        <table v-if="item.evidencias && item.evidencias.length" class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <th scope="col" class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">Evidências</th>
                                <th scope="col" class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">Opções</th>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(doc, index) in item.evidencias" :key="doc.filename">
                                <td class="px-2 py-2 text-sm whitespace-nowrap">
                                    {{doc.originalname}}
                                </td>
                                <td class="px-2 py-2 whitespace-nowrap">
                                    <a 
                                    type="button"
                                    target="_blank"
                                    :href="`${url_api}/upload?mimetype=${doc.mimetype}&filename=${doc.filename}&folder=empresas`"
                                    class="mr-1 inline-flex justify-center py-1 px-3 border border-transparent shadow-sm text-xs_3 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400">
                                    Visualizar
                                    </a>
                                    <button @click="excluirEvidencia(idx, index)" class="mr-1 inline-flex justify-center py-1 px-3 border border-transparent shadow-sm text-xs_3 font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-400" type="button">Excluir</button>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div 
                    class="pb-4 flex flex-col gap-4"
                    v-show="(item.resposta === 'Não' || item.resposta === 'Parcialmente') && diagnostico.tipoDiagnostico === 'interno'" 
                    :id="item._id"
                >
                    <section class="bg-slate-200 text-sm">
                        <div class="flex bg-slate-300 p-4 justify-between" >
                            <div class="flex">
                                <svg class="mr-2 fill-black" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256"><path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z"></path></svg>
                                <span class="mt-1">
                                    Registro de vulnerabilidades ou não conformidades para o registro de plano de ação integral - momento 1
                                </span>
                            </div>

                            <svg v-if="!item.visivel" @click="item.visivel = !item.visivel" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path></svg>
                            <svg v-else @click="item.visivel = !item.visivel" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M213.66,165.66a8,8,0,0,1-11.32,0L128,91.31,53.66,165.66a8,8,0,0,1-11.32-11.32l80-80a8,8,0,0,1,11.32,0l80,80A8,8,0,0,1,213.66,165.66Z"></path></svg>
                        </div>
                        <div 
                            v-show="item.visivel" 
                            class="grid grid-cols-1 p-4 gap-4">
                            
                            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 items-start gap-2">
                                <section class="col-span-2 lg:col-span-4 grid grid-cols-1 md:grid-cols-2 gap-2">
                                    <div class="flex flex-col">
                                        1 - Vulnerabilidades/Não conformidades
                                        <div class="flex">
                                            <multiselect
                                                v-model="item.vulnerabilidades"
                                                :options="vulnerabilidades"
                                                :multiple="true"
                                                :close-on-select="false"
                                                label="nome"
                                                trackBy="_id"
                                                @input="onSelectVulnerabilidadePAI($event, idx)" 
                                                placeholder="selecione as vulnerabilidades"
                                                selectedLabel="selecionado"
                                                deselectLabel="Pressione Enter para remover"
                                                selectLabel="Pressione Enter para selecionar"
                                                class="mt-2"
                                            />
                                            <svg
                                                @click="abrirModalVulnerabilidadePAI(idx)"
                                                fill="#059669"
                                                viewBox="0 0 256 256" 
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="mx-1 mt-4 h-6 inline text-green-600"
                                            >
                                                <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="flex flex-col">
                                        2 - Condutas/controles a serem implementados
                                        <div class="flex">
                                            <multiselect
                                                v-model="item.respostaNao"
                                                :options="condutasMitigacao"
                                                :multiple="true"
                                                :close-on-select="false"
                                                label="nome"
                                                trackBy="_id"
                                                placeholder="selecione as condutas"
                                                selectedLabel="selecionado"
                                                deselectLabel="Pressione Enter para remover"
                                                selectLabel="Pressione Enter para selecionar"
                                                class="mt-2"
                                            />
                                            <svg
                                                @click="abrirModalCondutaPAI(idx)"
                                                fill="#059669"
                                                viewBox="0 0 256 256"
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="mx-1 mt-4 h-6 inline text-green-600"
                                            >
                                                <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </section>
                                <section class="col-span-2 grid grid-cols-3 gap-2">
                                    <div class=" flex flex-col">
                                        <span>
                                            3 - Complexidade
                                        </span>
                                        <select v-model="item.pai.complexidade" name="complexidade" id="complexidade"
                                            class="text-black py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm">
                                            <option value="Baixo">Baixo</option>
                                            <option value="Médio">Médio</option>
                                            <option value="Alto">Alto</option>
                                        </select>
                                    </div>
                                    <div class=" flex flex-col">
                                        4 - Grau de risco
                                        <select v-model="item.pai.grauDerisco" name="grauDerisco" id="grauDerisco" 
                                            class="text-black py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm">
                                            <option value="Muito Baixo">Muito Baixo</option>
                                            <option value="Baixo">Baixo</option>
                                            <option value="Médio">Médio</option>
                                            <option value="Alto">Alto</option>
                                            <option value="Muito Alto">Muito Alto</option>
                                        </select>
                                    </div>
                                    <div class="flex flex-col">
                                        5 - Aprovação
                                        <select v-model="item.pai.condutaAprovada" name="condutaAprovada" id="condutaAprovada" 
                                            class="text-black py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm">
                                            <option value="Sim">Sim</option>
                                            <option value="Não">Não</option>
                                            <option value="Parcialmente">Parcialmente</option>
                                        </select>
                                    </div>
                                </section>
                            </div>
                            <div class="flex gap-2 flex-wrap lg:flex-nowrap">
                                <div class="flex flex-col">
                                    6 - Prazo de execução
                                    <div class="flex  flex-wrap md:flex-nowrap space-x-1">
                                        <input v-model="item.pai.prazoDeExecucao.de" type="date" class="block shadow-sm sm:text-sm border-gray-300 rounded-md text-gray-900">
                                        <input v-model="item.pai.prazoDeExecucao.ate" type="date" class="block shadow-sm sm:text-sm border-gray-300 rounded-md text-gray-900">
                                    </div>
                                </div>
                                <div class="grid grid-cols-2 gap-2 w-full">
                                    <div class="flex flex-col w-full">
                                        7 - Aprovador
                                        <input 
                                            v-model="item.pai.quemAprovou" 
                                            placeholder="Digite o nome" 
                                            type="text" 
                                            class="text-black py-1.5 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                                        >
                                    </div>  
                                    <div class="flex flex-col w-full">
                                        8 - Observações
                                        <textarea 
                                            v-model="item.pai.obs" 
                                            class="rounded-md border-gray-300 sm:text-sm text-gray-900"
                                            name="obs" id="obs"
                                            rows="1"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                                <div class="flex flex-col w-full" id="multiselect_responsaveis_conduta">
                                    9 - Responsaveis pela conduta
                                    <multiselect 
                                        v-model="item.pai.responsaveisPelaConduta" 
                                        :options="agentesProfissionaisPessoas" 
                                        :multiple="true" 
                                        group-values="arr" group-label="tipo"
                                        :close-on-select="false" 
                                        placeholder="Digite aqui para filtrar" 
                                        tagPlaceholder="" 
                                        noOptionsText="" 
                                        selectedLabel="" 
                                        deselectLabel="" 
                                        selectLabel="" 
                                        class="shadow-sm sm:text-sm border-gray-300 rounded-md "
                                        track-by="_id" label="nome"
                                    >
                                        <template #tag="props">
                                            <span class="option__desc space-x-1">
                                                <span :class="
                                                    {
                                                        'bg-yellow-400': props.option.tipo === 'Fornecedor',
                                                        'bg-blue-500': props.option.tipo === 'Profissional',
                                                        'bg-green-500': props.option.tipo === 'Pessoa',
                                                    }
                                                " class="mx-1 p-1 text-white rounded-md">
                                                    {{ props.option.nome }}
                                                </span>
                                            </span>
                                        </template>

                                        <template #option="props">
                                            <div 
                                                :class="
                                                    {
                                                        'bg-yellow-400': props.option.tipo === 'Fornecedor',
                                                        'bg-blue-500': props.option.tipo === 'Profissional',
                                                        'bg-green-500': props.option.tipo === 'Pessoa',
                                                    }
                                                "

                                                class="option__desc p-1"
                                            >
                                                <span class="option__title" v-if="props.option.$isLabel">{{ props.option.$groupLabel }}</span>
                                                <span class="option__title" v-if="!props.option.$isLabel">{{ props.option.nome }}</span>
                                            </div>
                                        </template>

                                        <template #noResult>
                                            Não encontrou
                                        </template>
                                    </multiselect>
                                </div>
                                <div class="flex flex-col">
                                    10 - Evidências
                                    <div class="w-full flex flex-col">
                                        <vue-dropzone
                                            ref="doc"
                                            id="doc"
                                            class="w-full rounded-lg"
                                            :options="dropzoneOptionsPAI"
                                            v-on:vdropzone-success="(file, response) => updateDoc(file, response, idx)"
                                            :style="{
                                                minHeight: '80px',
                                                height: '80px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                textAlign: 'center',
                                                fontSize: '14px',
                                                padding: '0'
                                            }"
                                        >
                                        </vue-dropzone>
                                        <table class="min-w-full divide-y divide-gray-200" v-if="item.pai.evidencias && item.pai.evidencias.length>0">
                                            <thead class="bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                <tr>
                                                    <th scope="col" class="px-6 py-3">
                                                        Arquivo
                                                    </th>
                                                    <th  scope="col" class="px-6 py-3">
                                                        Opção
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200 text-sm text-gray-500">
                                                <tr 
                                                    v-for="(evid, indexEvidencia) in item.pai.evidencias" 
                                                    :key="indexEvidencia"
                                                >
                                                    <td class="px-6 py-4 ">
                                                        {{evid.originalname ? evid.originalname : ''}}
                                                    </td>
                                                    <td class="px-6 py-4 flex gap-2 flex-wrap items-center">
                                                        <a 
                                                            class="text-blue-500"
                                                            target="_blank" 
                                                            :href="`${url_api}/upload?mimetype=${evid.mimetype}&filename=${evid.filename}&folder=empresas`">
                                                            Visualizar
                                                        </a>
                                                        <button
                                                            type="button"
                                                            @click="item.pai.evidencias.splice(indexEvidencia, 1)" 
                                                            class="justify-center py-1 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                                            Remover
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="flex gap-2 col-span-1 md:col-span-2 lg:col-span-1 text-sm">
                                    <section class="flex flex-col">
                                        11 - Status
                                        <select v-model="item.pai.status" name="status" id="status" @change="gerarDataConclusao(item.pai.status, idx)"
                                            class="text-black py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm">
                                            <option value="Pendente">Pendente</option>
                                            <option value="Em andamento">Em andamento</option>
                                            <option value="Concluído">Concluído</option>
                                        </select>
                                    </section>
                                    <section class="flex flex-col w-full">
                                        12 - Incluir no Plano de Ação Integral (PAI)
                                        <select 
                                            v-model="item.pai.incluirPAI" 
                                            name="incluirPAI" id="incluirPAI"
                                            class="text-black py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                                        >
                                            <option :value="true">Sim</option>
                                            <option :value="false" default>Não</option>
                                        </select>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
           
            <div class="flex flex-wrap justify-end gap-2">
                <button @click="$router.push({ path: `/diagnosticos/visualizar/${$route.params.id}`})" v-if="$store.state.user._id" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Voltar
                </button>
                <button 
                    v-if="diagnostico && !diagnostico.finalizado && $store.state.user._id" 
                    @click="salvar()" 
                    type="button" 
                    class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400"
                >
                    Salvar
                </button>
            </div>
        <modal name="sucessoFinalizar" :adaptive="true" :height="'auto'">
            <div id="idmodal" class="px-3 py-4 overflow-auto h-full md:h-auto">
                <h1 class="font-r font-semibold text-2xl mb-4">Questionário finalizado com sucesso!</h1>
                <button @click="$modal.hide('sucessoFinalizar')" class="text-white hover:bg-gray-600 bg-green-500 rounded py-2 px-12">
                    OK
                </button>
            </div>
        </modal>
        <modal name="addEvidencia" :width="800" :height="'auto'" :adaptive="true" :reset="true">
            <div class="px-3 py-2 overflow-y-auto h-full">
                <h2 class="text-base font-semibold mb-2"> 
                    Adicionar documento 
                </h2>
                <vue-dropzone 
                    ref="doc" id="doc" 
                    class="w-full" 
                    :options="dropzoneOptions" 
                    v-on:vdropzone-success="update">
                </vue-dropzone>
            </div>
        </modal>
        <modal name="modalVulnerabilidadesPAI" :adaptive="true" :height="'auto'" :clickToClose="false">
            <div class="px-5 py-5">
                <h2 class="text-xl font-bold mb-4"> Nova Vulnerabilidade </h2>
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <label for="nome" class="block text-sm font-medium">Nome</label>
                        <input
                            v-model="novoItemPAI.nome"
                            type="text"
                            name="novaVulnerabilidadePAI"
                            id="novaVulnerabilidadePAI"
                            class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                        >
                    </div>
                </div>

                <div class="grid grid-cols-2 mt-3">
                <div class="py-3 text-left">
                    <button @click="$modal.hide('modalVulnerabilidadesPAI')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                </div>
                <div class="py-3 text-right">
                    <button @click="adicionarVulnerabilidadePAI" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Salvar
                    </button>
                </div>
            </div>
            </div>
        </modal>
        <modal name="modalCondutaPAI" :adaptive="true" :height="'auto'" :clickToClose="false">
            <div class="px-5 py-5">
                <h2 class="text-xl font-bold mb-4"> Nova conduta </h2>
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <label for="nome" class="block text-sm font-medium">Nome</label>
                        <input
                            v-model="novoItemPAI.nome"
                            type="text"
                            name="novaCondutaPAI"
                            id="novaCondutaPAI"
                            class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                        >
                    </div>
                </div>
             
                <div class="grid grid-cols-2 mt-3">
                    <div class="py-3 text-left">
                        <button @click="$modal.hide('modalCondutaPAI')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Voltar
                        </button>
                    </div>
                    <div class="py-3 text-right">
                        <button @click="adicionarCondutaPAI" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </modal>
        </div>
    </div>
</template>


<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import moment from 'moment'
export default {
    components: {
      vueDropzone,
    },
    data() {
        return {
            route: '/diagnosticos',
            diagnostico: null,
            perguntas: [],
            vulnerabilidades: [],
            itemAddEvidencia: null,
            novoItemPAI: {
                nome:'',
                index: null,
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
            `upload`,
            'post',
            {
              maxFiles: 1,
              addRemoveLinks: true,
              capture: true,
              dictDefaultMessage: 'Clique ou arraste o arquivo aqui',
              dictRemoveFile: 'Remover'
            }
            ),
            dropzoneOptionsPAI: this.$http.getDropzoneConfig(
                `v1/upload`,
                'post',
            {
                maxFiles: 2,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Anexe aqui evidências da conduta realizada/controle aplicado',
                dictRemoveFile: 'Remover',
                acceptedFiles: '.jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.odt,.ods,.odp,.odg,.odf'
            }),
            agentesProfissionaisPessoas: [],
        }
    },
    methods: {
        async updateDoc(file, response, idx) {
            if (this.perguntas[idx].pai) this.perguntas[idx].pai.evidencias.push(response.file);
            else console.error(`Índice ${idx} da pergunta não encontrado`);
        },
        abrirModalVulnerabilidadePAI(idx) {
            this.novoItemPAI.index = idx;
            this.$modal.show('modalVulnerabilidadesPAI');
        },
        abrirModalCondutaPAI(idx) {
            this.novoItemPAI.index = idx;
            this.$modal.show('modalCondutaPAI');
        },
        async adicionarVulnerabilidadePAI() {
            const index = this.novoItemPAI.index

            const req = await this.$http.post(`/v1/vulnerabilidades`, this.novoItemPAI);
            if (req.data.success) {
                this.$vToastify.success("Vulnerabilidade adicionada com sucesso!");
                this.$modal.hide('modalVulnerabilidadesPAI');

                this.vulnerabilidades.push(req.data.data);
                this.diagnostico.perguntas[index].vulnerabilidades.push(req.data.data);
            }else{
                const error = req.data && req.data.err ? req.data.err : `A vulnerabilidade já existe`
                return this.$vToastify.error(error);
            }

            this.novoItemPAI.index = null;
            this.novoItemPAI.nome = '';
        },
        async adicionarCondutaPAI() {

            const index = this.novoItemPAI.index

            const req = await this.$http.post(`/v1/condutasmitigacao`, this.novoItemPAI);
            if (req.data.success) {
                this.$vToastify.success("Conduta adicionada com sucesso!");
                this.$modal.hide('modalCondutaPAI');

                this.condutasMitigacao.push(req.data.data);
                this.diagnostico.perguntas[index].respostaNao.push(req.data.data);
            }else{
                const error = req.data && req.data.err ? req.data.err : `A conduta já existe`
                return this.$vToastify.error(error);
            }

            this.novoItemPAI.index = null;
            this.novoItemPAI.nome = '';
        },
        onSelectVulnerabilidadePAI (itens, index) {
            itens?.forEach(item => {
                for (let i = 0; i < item.condutas?.length; i++){
                    const c = item.condutas[i];

                    if (!this.diagnostico.perguntas[index].respostaNao?.some(cdt => cdt?.nome === c?.nome)) {
                        this.diagnostico.perguntas[index].respostaNao.push(c);
                    }
                }
            });
        },
        gerarDataConclusao(status, idx){
            if(status === 'Concluído'){
                this.diagnostico.perguntas[idx].pai.concluidoEm = new Date();
            }else{
                this.diagnostico.perguntas[idx].pai.concluidoEm = null;
            } 
        },
        async start(){
            const reqForProPess = await this.$http.post(`/v1/diagnosticoAplicado/forpropess`)
            this.agentesProfissionaisPessoas = reqForProPess.data
            const id = this.$route.params.idAplicado;
            const idpergunta = this.$route.params.idpergunta

            const origem = this.$route?.query?.origem || ''
            const id_usuario = this.$store?.state?.user?._id || '';
            
            if (id) {
                await this.$http.get(`/v1/diagnosticoAplicado/${id}?origem=${origem}&usuario=${id_usuario}`)
                .then(resp => {

                    if(resp?.data?.err){
                        this.$vToastify.error(resp?.data?.err);
                        return this.$router.push({ path: this.route});
                    }

                    resp.data.perguntas.map(p => {
                        if (idpergunta && p._id !== idpergunta) p.visivel = false
                        if (idpergunta && p._id === idpergunta) p.visivel = true
                        
                        if(p.pai.prazoDeExecucao.de) p.pai.prazoDeExecucao.de = moment(p.pai.prazoDeExecucao.de).format("YYYY-MM-DD")
                        if(p.pai.prazoDeExecucao.ate) p.pai.prazoDeExecucao.ate = moment(p.pai.prazoDeExecucao.ate).format("YYYY-MM-DD")
                    })

                    this.diagnostico = resp.data;
                    this.perguntas = resp.data.perguntas;
 
                    if (idpergunta) {
                        setTimeout(() => {
                            const element = document.getElementById(idpergunta);
                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth' });
                                element.style.border = '2px red solid'
                            }
                        }, 1000);
                    }
                })
                .catch(()=> {
                    this.$vToastify.error("Perguntas não encontradas.");
                    this.$router.push({ path: this.route})
                })
            }
        },
        async salvar(status) {
            this.perguntas.map(p => {
                p.pai.prazoDeExecucao.de = moment(p.pai.prazoDeExecucao.de).startOf("day").toDate()
                p.pai.prazoDeExecucao.ate = moment(p.pai.prazoDeExecucao.ate).startOf("day").toDate()
            })

            if(status)  this.diagnostico.status = status
            this.diagnostico.perguntas = [...this.perguntas]
            await this.$http.put(`v1/diagnosticoAplicado/`,  this.diagnostico)
            .then(() => {
                this.$vToastify.success(status ? 'Diagnóstico finalizado':'Diagnóstico salvo')

                this.perguntas.map(p => {
                    p.pai.prazoDeExecucao.de = moment(p.pai.prazoDeExecucao.de).format("YYYY-MM-DD")
                    p.pai.prazoDeExecucao.ate = moment(p.pai.prazoDeExecucao.ate).format("YYYY-MM-DD")
                })
            })
            .catch(()=>this.$vToastify.error('Erro ao salvar diagnóstico'))
        },
        async update(file, response) {
            if(response.file) this.perguntas[this.idxAddEvidencia].evidencias.push(response.file);
            this.$refs.doc.removeFile(file);
            this.itemAddEvidencia = null;
            this.$modal.hide('addEvidencia');
        },
        excluirEvidencia(idxPergunta, index) {
            if(this.perguntas[idxPergunta] && this.perguntas[idxPergunta].evidencias) this.perguntas[idxPergunta].evidencias.splice(index, 1);
        },
        addEvidencia(idx){
            this.idxAddEvidencia = idx;
            this.$modal.show('addEvidencia');
        }
    },
    async beforeMount() {

        const vulnerabilidades = await this.$http.post(`/v1/vulnerabilidades/list`, { all: true});
        this.vulnerabilidades = vulnerabilidades.data.data;

        const condutasMitigacaoReq = await this.$http.post(`/v1/condutasmitigacao/list`,{ all: true});
        this.condutasMitigacao = condutasMitigacaoReq.data.data;

        this.start();
    },
}
</script>
<style>
/* Ajusta o tamanho da pré-visualização do arquivo */
#doc .dz-preview {
    width: 80px !important;
    height: 80px !important;
    min-height: 80px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

#doc .dz-remove {
    color: red !important;
    border: 2px solid red !important;
    font-weight: bold;
    padding: 2px 10px;
    border-radius: 4px;
    position:top,
}

#doc .dz-filename {
    color: black !important;
    font-size: 10px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}
</style>